
/* Color palette */

$greenish-teal: #4ccead;
$greeny-blue-two: #41c69d;
$greeny-blue: #41af93;
$ocean-green: #348c75;

$whisper-grey: #eee;
$pale-grey: #e7eaed;
$white-smoke: #f6f6f6;
$white-smoke2: #f8f8f8;
$pinkish-grey: #ccc;
$greyish-two: #aeaeae;
$greyish: #aaa;
$greyish-brown: #545454;
$pinkish-grey-two: #bbb;
$warm-grey-two: #8b8b8b;
$warm-grey-three: #888;
$warm-grey: #777;
$charcoal-grey: #333;
$nero: #262626;

$dark-coral: #ce4c4c;
$sunflower-yellow: #ffd200;
$ugly-yellow: #e0b800;
$dark-sky-blue: #4c9fe2;

$dusty-blue: #607cb3;

$header-font: 'Montserrat', sans-serif;
$text-font: 'Roboto', sans-serif;

$border-color: #e7e7e7;
$typical-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.05);
$section-background: #f9f9f9;

$mainTextColor: #333333;
$headingsTextColor: #2a2a32;
$mainTenantColorDarker: #69696e;
$secondaryTenantColor: #727272;
$iconsColor: #2a2a32;
$supportColor: #ccc;

$mainTenantColor: #2a2a32;
$mainTenantColorHover: $secondaryTenantColor;

$mainBtnColor: $mainTenantColor;
$mainBtnColorHover: $secondaryTenantColor;
$secondaryBtnColor: $pinkish-grey;
$secondaryBtnColorHover: $greyish-two;
