.svg-icon {
  display: inline-block;
  vertical-align: top;
  color: inherit;
  fill: currentColor;
  pointer-events: none;
}

.status-icon {
  position: absolute;
  right: 12px;
  top: 12px;

  &.not-started-icon {
    color: $greyish-two;
  }

  &.done-icon {
    color: $greenish-teal;
  }
}

.delete-icon {
  position: absolute;
  left: 12px;
  top: 12px;
  color: $pinkish-grey-two;

  &:hover {
    color: $charcoal-grey;
  }
}

.help-mark {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 100%;
  border: solid 2px $mainTenantColor;

  font-family: $header-font;
  font-size: 20px;
  line-height: 36px;
  font-weight: bold;
  text-align: center;
  color: $mainTenantColor;
}

.add-icon_big {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;

  &::before, &::after {
    content: "";
    position: absolute;
    background: $charcoal-grey;
  }

  &::before {
    top: 0;
    left: 50%;
    width: 16%;
    height: 100%;
    transform: translateX(-50%);
  }
  &::after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 16%;
    transform: translateY(-50%);
  }
}
