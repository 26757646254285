.success-page {
  &__wrap {
    padding: 25px 50px 30px;

    background: #ffffff;
    box-shadow: $typical-box-shadow;
  }

  &__header {
    margin-bottom: 25px;
    line-height: 26px;
  }

  &__text {
    line-height: 24px;
  }

  &__button {
    min-width: 170px;
  }

  &__icon {
    margin: 0 0 25px;
    color: $mainTenantColor;
  }
}
