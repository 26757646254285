.bc-btn {
  padding: 14px 19px;
  margin: 0;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 19px;
  font-family: $header-font;
  font-weight: 400;
  color: #fff;

  outline: none !important;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: #fff;
  }

  &:focus {
    outline: none;
  }

  &_small {
    padding-top: 10px;
    padding-bottom: 11px;
  }

  &_tiny {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &_default {
    background-color: $secondaryBtnColor;
    &:hover {
      background-color: $secondaryBtnColorHover;
    }
    &:active, &:focus,
    &.bc-btn_disable, &:disabled {
      background-color: $secondaryBtnColor;
      &:hover {
        background-color: $secondaryBtnColor;
      }
    }
  }

  &_custom {
    background-color: $mainBtnColor;
    &:hover {
      background-color: $mainBtnColorHover;
    }
    &:active, &:focus,
    &.bc-btn_disable, &:disabled {
      background-color: $mainBtnColor;
      &:hover {
        background-color: $mainBtnColor;
      }
    }
  }

  &_disable, &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    &.status {
      opacity: 1;
      cursor: default;
    }
  }

  &_block {
    display: block;
    width: 100%;
  }

  &__icon {
    display: inline-block;
    opacity: 0.5;
    color: inherit;
    &_left {
      margin-right: 5px;
    }
    &_topfix {
      vertical-align: -2px;
    }
  }

  &_incomplete {
    background: $sunflower-yellow;
    box-shadow: 0 3px 0 0 $ugly-yellow;
  }

  @include breakpoint(md-max) {
    padding: 10px 8px;
  }
}

.bc-btn_bordered {
  background: transparent;
  border: 1px solid $mainBtnColor;
  color: $mainBtnColor;

  &.bc-btn_active, &:hover,
  &.bc-btn_active:active,
  &.bc-btn_active:focus {
    border-color: $mainBtnColorHover;
    background: $white-smoke2;
    color: $mainBtnColorHover;
  }

  &:active, &:focus, &:disabled,
  &.bc-btn_disable,
  &.bc-btn_disable:hover,
  &:disabled:hover {
    border-color: $mainBtnColor;
    background: #fff;
    color: $mainBtnColor;
  }
  &_disable, &:disabled {
    opacity: 0.5;
  }

  .bc-btn__icon {
    opacity: 1;
    color: inherit;
  }
}

.bc-btn_back {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $mainBtnColor;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: $mainBtnColor;
  }

  i {
    padding-right: 10px;
    font-size: 18px;
  }

  .btn_text {
    font-family: "Montserrat",sans-serif;
    font-size: 16px;
    position: relative;
    top: 1px;
    font-weight: 500;
  }
}

.bc-btn-link {
  display: inline-block;
  text-decoration: none;
  color: $mainTenantColor;

  &:hover, &:active, &:focus {
    text-decoration: underline;
    color: $mainTenantColorDarker;
  }

  &_disable, &:disabled {
    opacity: 0.5;
  }
}

.bc-btns_inline-wrap {
  .bc-btn + .bc-btn {
    margin-left: 10px;
  }
}
