/* Text styles */

.h1-header {
  margin: 0;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 500;
  font-family: $header-font;
  &_charcoal {
    color: $charcoal-grey;
  }
  &_light {
    font-weight: 400;
  }
  &_colored {
    color: $mainTenantColor;
  }
}

.h2-header {
  margin: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  font-family: $header-font;
  &_charcoal {
    color: $charcoal-grey;
  }
  &_nero {
    color: $nero;
  }
  &_light {
    font-weight: 400;
  }
}

.h3-header {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: $header-font;
  &_charcoal {
    color: $charcoal-grey;
  }
  &_nero {
    color: $nero;
  }
  &_light {
    font-weight: 400;
  }
}

.h4-header {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: $header-font;
  &_nero {
    color: $nero;
  }
  &_charcoal {
    color: $charcoal-grey;
  }
  &_light {
    font-weight: 400;
  }
}

.h5-header {
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  font-family: $header-font;
  &_nero {
    color: $nero;
  }
  &_charcoal {
    color: $charcoal-grey;
  }
  &_light {
    font-weight: 400;
  }
}

.main-text {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: $text-font;
  &_nero {
    color: $nero;
  }
  &_warm-grey {
    color: $warm-grey;
  }
  &_charcoal {
    color: $charcoal-grey;
  }
}

.settings-content__header {
  font-size: 18px;
}