.field_with-btns {
  .bc-btn {
    float: right;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0 0 0 10px;
  }

  .input_field {
    display: block;
    overflow: hidden;
    width: calc(100% - 115px);
  }

  .bc-btn__icon {
    vertical-align: middle;
  }

  @include breakpoint(md-max) {
    .bc-btn {
      padding-right: 12px;
      padding-left: 12px;
    }

    .input_field {
      width: calc(100% - 98px);
    }
  }
}
