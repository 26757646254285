/* You can add global styles to this file, and also import other style files */
@import 'assets/sass/00-utilities/variables';

body, html {
  height: 100%;
  width: 100%;
  background: $section-background;
  color: $charcoal-grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bc-loading-screen {
  width: 400px;
  max-width: 100%;
  margin: 15% auto 0;
  text-align: center;
}


@import 'assets/sass/00-utilities/mediaquery';
@import 'assets/sass/00-utilities/mixins';
@import 'assets/sass/00-utilities/helpers';

@import 'assets/sass/01-atoms/icons';
@import 'assets/sass/01-atoms/link';

@import 'assets/sass/01-atoms/form';
@import 'assets/sass/01-atoms/field_with-btns';

@import 'assets/sass/01-atoms/buttons';
@import 'assets/sass/01-atoms/typography';
@import 'assets/sass/01-atoms/grid';

@import 'assets/sass/02-molecules/warning-block';
@import 'assets/sass/02-molecules/auth-form';
@import 'assets/sass/02-molecules/success-page';
@import 'assets/sass/02-molecules/section-button';
@import 'assets/sass/02-molecules/preview-block';
@import 'assets/sass/02-molecules/nav';
@import 'assets/sass/02-molecules/dx-popover';

@import 'assets/sass/02-molecules/dx-tag-box';
@import 'assets/sass/02-molecules/dx-file-uploader';
@import 'assets/sass/02-molecules/documents_list';

