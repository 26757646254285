.select_field {
  height: 47px;
  margin: 0;
  box-shadow: none;
  border: 1px solid $border-color !important;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;

  .dx-texteditor-input {
    padding-left: 14px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-family: $text-font;
    &::placeholder,
    &::-webkit-input-placeholder, /* Chrome/Opera/Safari */
    &::-moz-placeholder, /* Firefox 19+ */
    &:-ms-input-placeholder, /* IE 10+ */
    &:-moz-placeholder {
      color: $greyish;
      opacity: 1;
    }
    &[disabled] {
      background-color: $white-smoke;
    }
  }

  @include breakpoint(md-max) {
    height: 39px;
  }
}
