.section-button {
  position: relative;
  display: flex;
  align-items: center;

  height: 80px;
  padding: 20px 25px 20px 80px;
  margin-bottom: 20px;

  box-sizing: border-box;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid $pinkish-grey;

  text-decoration: none;
  text-align: left;
  font-size: 20px;
  font-family: $header-font;
  line-height: 1.5;
  font-weight: 300;
  color: $nero;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    color: $charcoal-grey;
  }

  &:hover {
    text-decoration: none;
    color: $charcoal-grey;
  }

  &__bc-notice {
    position: absolute;
    right: 25px;
    top: 50%;
    margin-top: -12px;
  }

  @include breakpoint(md-max) {
    font-size: 16px;
  }
}


.content-block_header + .section-button {
  margin-top: 25px;
}
