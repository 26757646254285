.fields__block {
  .fields__block__label {
    display: block;
    margin-bottom: 6px;
  }

  .fields__block__info-label {
    margin: 0 0 6px;
    color: $warm-grey;
  }

  & + .fields__block {
    margin-top: 25px;
  }
}

.fields__separator {
  display: block;
  width: 100%;
  height: 1px;
  margin: 25px 0 15px;
  background: $whisper-grey;
}

.field__item {
  margin-bottom: 15px;
}

.field__item.horizontal {
  overflow: hidden;

  .field__label {
    display: block;
  }
  .radio__field {
    float: left;
    margin-right: 30px;
  }
}

.field__label {
  display: inline;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  font-family: $header-font;
  color: $charcoal-grey;

  &_bigger {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  }
  &_medium {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}

.field__help-block {
  margin: 2px 0 0;
  font-family: $text-font;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: $charcoal-grey;
}

.error-message {
  font-size: 12px;
  line-height: 19px;
  font-weight: normal;
  font-family: $text-font;
  color: $dark-coral;
}

.checkbox__field,
.radio__field {
  position: relative;
  min-height: 24px;
}

.radio__field input[type=radio] {
  position: absolute;
  visibility: hidden;
  &:checked ~ .radio__check {
    border: 2px solid $mainTenantColor;
    background-color: $mainTenantColor;
  }

  &:checked ~ .radio__label {
    color: $charcoal-grey;
  }

  &[disabled]:not(:checked) ~ .radio__check {
    background-color: $white-smoke;
  }
}

.checkbox__field input[type=checkbox] {
  position: absolute;
  visibility: hidden;
  &:checked ~ .checkbox__check {
    border: 2px solid $mainTenantColor;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 4px;
      width: 7px;
      height: 11px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: $mainTenantColor;
      transform: rotate(45deg);
    }
  }

  &[disabled] ~ .checkbox__check {
    background-color: $white-smoke;
  }

  &:checked ~ .checkbox__label {
    color: $charcoal-grey;
  }
}

.checkbox__label,
.radio__label {
  position: relative;
  z-index: 3;
  display: block;
  margin: 0;
  padding: 0 0 0 27px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;

  &_smaller {
    font-size: 14px;
    line-height: 18px;
  }
}

.checkbox__check,
.radio__check {
  display: block;
  position: absolute;
  border: 2px solid $pinkish-grey;
  background: #fff;
  height: 18px;
  width: 18px;
  top: 3px;
  left: 0;
  z-index: 2;
  transition: border .25s linear;

  &[disabled] {
    background-color: $white-smoke;
  }
}

.radio__check {
  border-radius: 100%;
}

.yesno__field {
  display: flex;

  .radio__field {
    margin-top: 0;
    margin-right: 20px;
  }
}

@import './fields/calendar_field';
@import './fields/select_field';
@import './fields/bc_new_input-group';

.input_field {
  appearance: none;

  height: 47px;
  padding: 11px 15px 12px;
  margin: 0;
  box-shadow: none;
  border: 1px solid $pinkish-grey;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;

  &::placeholder,
  &::-webkit-input-placeholder, /* Chrome/Opera/Safari */
  &::-moz-placeholder, /* Firefox 19+ */
  &:-ms-input-placeholder, /* IE 10+ */
  &:-moz-placeholder {
    color: $greyish;
    opacity: 1;
  }

  &:focus {
    box-shadow: inset 0 0 0 1px $pinkish-grey;
    border-color: $pinkish-grey;
    background-color: #fff;
  }

  &[disabled] {
    background-color: $white-smoke;
  }

  &.ng-invalid:not(.ng-pristine) {
    border: 1px solid $dark-coral;
  }

  &__small {
    height: 40px;
    padding: 10px 9px 11px;
  }

  &__tiny {
    height: 37px;
    padding: 10px 9px 11px;
  }

  @include breakpoint(md-max) {
    height: 39px;
  }
}

.bc_input-group {
  position: relative;
  display: table;
  background-color: #fff;
  border-collapse: separate;
  border-radius: 4px;
  width: 100%;

  .input_field {
    &:focus {
      box-shadow: none;
    }
    &.no-left-border {
      padding-left: 0;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.no-right-border {
      padding-right: 0;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &-addon {
    display: table-cell;
    width: 1%;
    border: 1px solid #ccc;
    border-radius: 4px;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    font-family: $text-font;
    line-height: 24px;
    color: $charcoal-grey;

    &:first-child {
      padding: 10px 3px 11px 15px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    &:last-child {
      padding: 10px 15px 11px 3px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }

  &.disabled {
    background-color: $white-smoke;
  }
}

.text_field {
  appearance: none;

  display: block;
  width: 100%;
  height: 105px;
  padding: 11px 15px 12px;
  margin: 0;
  box-shadow: none;
  resize: none;
  border: 1px solid $pinkish-grey;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;

  &::placeholder,
  &::-webkit-input-placeholder, /* Chrome/Opera/Safari */
  &::-moz-placeholder, /* Firefox 19+ */
  &:-ms-input-placeholder, /* IE 10+ */
  &:-moz-placeholder {
    color: $greyish;
    opacity: 1;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px $pinkish-grey;
    background-color: #fff;
  }

  &[disabled] {
    background-color: $white-smoke;
  }

  &.ng-invalid:not(.ng-pristine) {
    border: 1px solid $dark-coral;
  }
}

.bc-switch {
  display: block;

  input { display:none; }

  .slider {
    position: relative;
    display: block;
    width: 85px;
    height: 37px;
    background-color: $pinkish-grey;
    box-shadow: 0 3px 0 0 $greyish-two;
    border-radius: 25px;
    line-height: 37px;
    font-weight: normal;

    transition: .4s;

    span {
      position: absolute;
      top: 0;
      z-index: 2;
      display: block;
      width: 52px;
      text-align: center;
      font-size: 14px;
      font-family: $header-font;
      color: #fff;
      &.checked {
        left: 29px;
        display: none;
      }
      &.unchecked {
        display: block;
      }
    }
  }

  .slider::before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    top: 3px;
    left: 50px;
    border-radius: 25px;
    background-color: #fff;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: $mainTenantColor;
    box-shadow: 0 3px 0 0 none;
    span.checked {
      display: block;
    }
    span.unchecked {
      display: none;
    }
  }

  input:checked + .slider::before {
    left: 3px;
  }
}

// Number field without standard appearance(arrows on the right side)
input.number-field_hidden-arrows[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
