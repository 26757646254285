dx-tag-box.bc-tag-box.dx-texteditor {
  border-color: $pinkish-grey !important;

  .dx-tag-content {
    background: $section-background;
    color: $charcoal-grey;
  }

  .dx-tag-remove-button:after {
    right: 5px;
    margin-top: -1px;
    width: 14px;
    height: 2px;
  }

  .dx-tag-remove-button:before {
    right: 11px;
    margin-top: -7px;
    width: 2px;
    height: 14px;
  }

  .dx-tag-remove-button:after,
  .dx-tag-remove-button:before {
    background: $charcoal-grey;
  }
}
