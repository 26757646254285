.preview_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 250px;
  padding: 25px 0 0;
  margin-top: 24px;
  background-color: #ffffff;
  border: 1px solid $pinkish-grey;
  border-radius: 5px;

  .bc-btn {
    align-self: stretch;
    margin: auto -1px -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
