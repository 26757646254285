.auth-form {
  padding: 15px 30px 25px;

  background: #ffffff;
  box-shadow: $typical-box-shadow;

  @include breakpoint(sm-max) {
    padding-right: 20px;
    padding-left: 20px;
  }

  &__separator {
    display: block;
    width: 100%;
    height: 1px;
    margin: 25px 0;
    background: $border-color;
  }

  &__link {
    margin-top: 20px;

    .bc-btn-link {
      font-family: $text-font;
      font-weight: 500;
    }

    @include breakpoint(sm-max) {
      text-align: center !important;

      .text-left, .text-right {
        text-align: center;
      }
    }
  }

  &__btn-row {
    margin-right: -8px;
    margin-left: -8px;

    [class*=col-] {
      padding-left: 8px;
      padding-right: 8px;
    }

    .bc-btn {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__icon {
    text-align: center;
  }
}
