.bc-notice__icon {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  color: $pinkish-grey;

  &:hover, &:active {
    color: $charcoal-grey;
  }
}

.fields__block__label .bc-notice__icon,
.field__label .bc-notice__icon,
.field__label + .bc-notice__icon {
  margin-left: 5px;
}

.dx-popup-wrapper > .dx-overlay-content {
  background: $section-background;
  border: 1px solid $border-color;
  box-shadow: none;
  border-radius: 0;
}

.dx-popover-wrapper .dx-popup-title.dx-toolbar {
  padding: 20px 20px 10px;
}

.dx-popover-wrapper .dx-popup-title,
.dx-popup-title.dx-toolbar .dx-toolbar-label {
  padding: 0;
  border-bottom: none;
  font-family: $header-font;
  font-size: 13px;
  line-height: 1.46;
  color: $charcoal-grey;
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: auto;
}

.dx-popup-title.dx-toolbar .dx-toolbar-before {
  padding: 0;
}

.dx-popup-content {
  background: $section-background;
  font-family: $header-font;
  font-size: 13px;
  line-height: 19px;
  font-weight: 300;
  color: $charcoal-grey;

  p {
    margin: 0;
    & + p {
      margin-top: 10px;
    }
  }
}

.dx-popover-wrapper .dx-popover-arrow:after {
  background: $section-background !important;
}
