.content__wrap {
  position: relative;
  min-height: calc(100% - 60px);
  padding-bottom: 80px;

  @include breakpoint(md-max) {
    .container {
      width: auto;
    }
  }
}

.heading_row {
  display: flex;
  align-content: center;

  height: 147px;

  [class*=col-] {
    display: flex;
    align-items: center;
  }

  .h1-header {
    margin: auto;

    @include breakpoint(md-max) {
      line-height: 1;
    }
  }

  @include breakpoint(sm-max) {
    display: block;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;

    .h1-header {
      margin-top: 15px;
      font-size: 20px;
    }
  }
}

// TODO: move to component after titles re-factoring
bc-titles {
  display: block;
  margin: auto;
}

.content_row {
  margin-right: -12px;
  margin-left: -12px;

  [class*=col-] {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.buttons_row {
  margin: 15px -6px;

  [class*=col-] {
    padding-left: 6px;
    padding-right: 6px;
  }
  @include breakpoint(sm-max) {
    .bc-btn {
      margin-bottom: 15px;
    }
  }
}

.content-block {
  padding: 0 30px 25px;
  margin-bottom: 15px;

  background-color: #ffffff;
  box-shadow: $typical-box-shadow;

  &_header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    height: 70px;
    padding: 10px;
    margin: 0 -30px;

    box-sizing: border-box;
    border-bottom: 1px solid $border-color;

    font-size: 16px;

    & + .main-text {
      margin-top: 18px;
    }
  }

  .main-text {
    line-height: 24px;
  }

  .row {
    margin-right: -6px;
    margin-left: -6px;
    [class*=col-] {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}
