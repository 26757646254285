.bc-documents-list {
  border-top: 1px solid $border-color;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;

  &__item {
    display: flex;
    align-items: center;

    width: 100%;
    height: 80px;
    border-bottom: 1px solid $border-color;
  }

  &__preview {
    max-width: 100%;
    max-height: calc(100% - 10px);
    margin: auto;
  }

  &__cell {
    display: inline-flex;
    align-items: center;
  }

  &__image {
    width: 54px;
    height: 100%;
  }

  &__name {
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;

    font-family: $text-font;
    font-size: 14px;
    color: $warm-grey;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__actions {
    flex: 0 0 60px;

    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    width: 60px;
    margin-left: auto;

    .icon-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      width: 30px;
      height: 30px;
    }
  }
}

@include breakpoint(sm-max) {
  .bc-documents-list {
    &__item {
      height: 60px;
    }

    &__preview,
    &__image,
    &__icon {
      width: 35px;
    }
  }
}
