.warning-block {
  position: relative;
  display: flex;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #fffdf3;
  border: solid 2px $sunflower-yellow;

  .icon-close-notification {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  &_icon {
    flex: 0 0 40px;
  }

  &_content {
    flex: 0 1 auto;
  }

  .h4-header {
    font-weight: 600;
  }

  .h4-header + .main-text {
    margin-top: 8px;
  }

  .main-text {
    line-height: 24px;
  }
}


