.bc-file-uploader {
  dx-file-uploader {
    width: 100%;
    margin-bottom: 40px;
  }

  .dx-fileuploader-wrapper {
    padding: 0;
  }

  .dx-fileuploader-input-wrapper {
    position: relative;

    width: 100%;
    height: 130px;
    padding: 0;
    border: none;

    &::before {
      padding: 0;
      margin: 0;
    }
  }

  .dx-fileuploader-input-container {
    position: relative;
    display: flex;
    background-color: $section-background;
    border: dashed 1px #e2e2e2;
  }

  .dx-fileuploader-input-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dx-fileuploader-button {
    position: absolute;
    top: 50%;
    z-index: 1;
    left: 50%;
    transform: translate(-105%, -50%);
  }

  .dx-fileuploader-dragover {
    .dx-fileuploader-input-label {
      padding: 0;
      text-align: unset;
    }

    .dx-fileuploader-input {
      height: 100%;
    }
  }

  .dx-invalid .dx-fileuploader-files-container {
    padding: 0;
  }

  .dx-fileuploader-show-file-list .dx-fileuploader-files-container {
    padding-top: 10px;
  }

  @include breakpoint(sm-max) {
    .dx-fileuploader-input-wrapper {
      height: 30px !important;
    }

    .dx-fileuploader-button {
      left: 0 !important;
      top: 0;
      transform: none;
    }

    .dx-fileuploader-input-container {
      display: none;
    }
  }
}
