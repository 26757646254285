.nav-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  @include u-clearfix;
}

.nav-menu__item {
  float: left;
  position: relative;
  border-right: 1px solid $whisper-grey;

  &:first-of-type {
    border-left: 1px solid $whisper-grey;
  }
}

.nav-menu__link {
  display: inline-block;
  padding: 19px 20px 20px;
  text-decoration: none;

  &:hover, &:active, &:focus, &_active {
    text-decoration: none;
    color: $charcoal-grey;
  }
  &:hover, &_active {
    background: $pale-grey;
  }
}

.nav-menu__icon {
  margin-right: 5px;
}

.nav-menu__item:hover .nav-menu_sub-menu {
  display: block;
}

.nav-menu_sub-menu {
  display: none;
  position: absolute;
  z-index: 12;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.nav-menu_sub-menu__item {
  border-bottom: 1px solid $whisper-grey;

  &:last-of-type {
    border-bottom: none;
  }
}

.nav-menu_sub-menu__link {
  display: block;
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;

  &:hover, &:active, &:focus, &_active {
    background: $white-smoke2;
    text-decoration: none;
    color: $charcoal-grey;
  }
}
