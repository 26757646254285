.bc_new_input-group {
  display: flex;
  background-color: #fff;
  border-collapse: separate;
  border-radius: 4px;
  width: 100%;

  .input-group_field {
    width: 100%;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }


  .input-group_addon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 47px;

    width: 47px;
    padding: 0;

    border: 1px solid $border-color !important;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    font-family: $text-font;
    line-height: 24px;
    color: $charcoal-grey;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }

    @include breakpoint(md-max) {
      flex: 0 0 39px;
      width: 39px;
    }
  }
}
