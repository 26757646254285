.calendar_field {
  .dx-texteditor {
    display: block;
    height: 47px;
    border: 1px solid $pinkish-grey;
    border-radius: 5px;
    font-size: 14px;
    line-height: 24px;
    .dx-texteditor-input {
      padding: 12px 50px 12px 14px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      font-family: $text-font;
      &::placeholder,
      &::-webkit-input-placeholder, /* Chrome/Opera/Safari */
      &::-moz-placeholder, /* Firefox 19+ */
      &:-ms-input-placeholder, /* IE 10+ */
      &:-moz-placeholder {
        color: $greyish;
        opacity: 1;
      }
      &[disabled] {
        background-color: $white-smoke;
      }
    }

    @include breakpoint(md-max) {
      height: 39px;

      .dx-texteditor-input {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
