// Usage example:
// @include breakpoint(md-max) {
//    max-width: 200px;
// }

@mixin breakpoint($point) {

  @if $point == md-max {
    @media (max-width: 991px) {
      @content;
    }
  }

  @if $point == md-min {
    @media (min-width: 992px) {
      @content;
    }
  }

  @if $point == sm-max {
    @media (max-width: 767px) {
      @content;
    }
  }

  @if $point == sm-min {
    @media (min-width: 768px) {
      @content;
    }
  }

  @if $point == xs-max {
    @media (max-width: 480px) {
      @content;
    }
  }
}
