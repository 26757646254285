.icon-link {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;

  &__icon {
    display: inline-block;
    vertical-align: -2px;
    color: $mainTenantColor;
    &:hover {
      color: $mainTenantColorDarker;
    }
  }

  &:hover {
    text-decoration: none;
    color: $mainTenantColor;
  }

  &_disable, &:disabled {
    opacity: 0.5;
  }
}

.text-link {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  font-family: $text-font;

  &_custom {
    color: $mainTenantColor;

    &:hover {
      color: $mainTenantColorHover;
    }
  }

  &_disable, &:disabled {
    opacity: 0.5;
  }
}

.back-link {
  display: inline-block;
  line-height: 24px;
  cursor: pointer;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: 1px;
    width: 0;
    height: 0;
    margin-right: 8px;
    border-style: solid;
    border-width: 4px 6px 4px 0;
    border-color: transparent $mainTenantColor transparent transparent;
  }

  &:hover {
    &:before {
      border-color: transparent $mainTenantColorDarker transparent transparent;
    }
  }
}

.next-link {
  display: inline-block;
  line-height: 24px;
  font-weight: normal;
  cursor: pointer;
  &::after {
    content: '';
    display: inline-block;
    vertical-align: 0;
    width: 0;
    height: 0;
    margin-left: 8px;
    border-style: solid;
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent $mainTenantColor;
  }

  &:hover {
    &::before {
      border-color: transparent transparent transparent $mainTenantColorDarker;
    }
  }
}
